function HorizontalSlider(objects, callbacks, options) {
    
    this.objects = objects;
    this.callbacks = callbacks === undefined ? {} : callbacks;
    this.options = options === undefined ? {} : options;
    this.scrolledTimeout = null;
    
    this.scrollDir = 1; // -1: left, 1: right
    this.scrollPos = 0; // last scroll pos
    
    // sliding makes a slide visible
    if(!this.callbacks.onSlideVisible) {
        this.callbacks.onSlideVisible = function() {};
    }
    // sliding ends
    if(!this.callbacks.onSlideEnd) {
        this.callbacks.onSlideEnd = function() {};
    }
    // sliding ends in most left position
    if(!this.callbacks.onSlideEndLeft) {
        this.callbacks.onSlideEndLeft = function() {};
    }
    // sliding ends in most right position
    if(!this.callbacks.onSlideEndRight) {
        this.callbacks.onSlideEndRight = function() {};
    }
    
    // init
    
    this.onResize(true);
    
    // bind events
    
    jQuery(window).resize(jQuery.proxy(function() {
        this.onResize(false);
    }, this));
    
    this.objects.slider.scroll(jQuery.proxy(function(e) {
        this.onScroll();
    }, this));
    
    // guided/locked scroll vs. natural free scroll
    
    if(this.options.scrollLock) {
        
        this.touchStartPos = null;
        this.touchStartScrollLeft = null;
        
        this.objects.slider.bind('touchstart', jQuery.proxy(function(e) {
            this.touchStartPos = e.originalEvent.changedTouches[0].clientX;
            this.touchStartScrollLeft = this.objects.slider[0].scrollLeft;
        }, this));
        
        this.objects.slider.bind('touchmove', jQuery.proxy(function(e) {
            var touchPos = e.originalEvent.changedTouches[0].clientX;
            var touchDiff = this.touchStartPos-touchPos;
            this.objects.slider[0].scrollLeft = this.touchStartScrollLeft + touchDiff;
        }, this));
        
        this.objects.slider.bind('touchend', jQuery.proxy(function(e) {
            var itemsScrolled = Math.ceil(this.objects.slider[0].scrollLeft / this.dimension.slideWidth);
            var delta = itemsScrolled * this.dimension.slideWidth - this.objects.slider[0].scrollLeft;
            if(delta > 10) {
                if(this.scrollDir < 0) {
                    this.scrollTo(Math.max(0, itemsScrolled-1) * this.dimension.slideWidth);
                } else if(this.scrollDir > 0) {
                    this.scrollTo(this.objects.slider[0].scrollLeft + delta);
                }
            }
        }, this));
    }
}

HorizontalSlider.prototype.slideLeft = function() {
    this.slide(-this.state.slidesPerSlide);    
};
HorizontalSlider.prototype.slideRight = function() {
    this.slide(this.state.slidesPerSlide);
};

/**
 * absolute slide based in itemIndexVisibleLeft
 * 
 * @param slide
 */
HorizontalSlider.prototype.slideTo = function(slide) {
    var offset = slide - this.state.itemIndexVisibleLeft;
    this.slide(offset);
};

/**
 * slide left on positive offset and right on negative
 * 
 * @param offset
 */
HorizontalSlider.prototype.slide = function(offset) {
    
    // sliding too far to the left
    while(this.state.itemIndexVisibleLeft + offset < 0) {
        offset++;
    }
    // sliding too far to the right
    while(this.state.itemIndexVisibleRight + offset > this.state.numberOfItems-1) {
        offset--;
    }
    if(offset === 0 && this.state.itemIndexVisibleRight === this.state.numberOfItems-1 && this.scrollDir > 0) {
        // special case: we are at last index but do not fully see last item
        var delta = Math.max(0, this.dimension.slidesWidth-this.dimension.sliderWidth-this.objects.slider[0].scrollLeft);
        if(delta > 0) {
            this.scrollTo(this.objects.slider[0].scrollLeft + delta);
        }
    }
    if(offset === 0) {
        return;
    }
    
    this.state.itemIndexVisibleLeft += offset;
    this.state.itemIndexVisibleRight += offset;
    
    // animate
    
    for(var i=this.state.itemIndexVisibleLeft,l=this.state.itemIndexVisibleRight;i<=l;i++) {
        this.callbacks.onSlideVisible(this.objects.slides[i], i);
    }
    
    this.scrollTo(this.state.itemIndexVisibleLeft * this.dimension.slideWidth);
};

HorizontalSlider.prototype.scrollTo = function(scrollLeft) {
    this.objects.slider.animate({
        'scrollLeft' : scrollLeft
    }, 100, 'swing', jQuery.proxy(function() {
        this.slideEnd();
    }, this));
};

HorizontalSlider.prototype.onScrollEnd = function() {
    
    var itemsScrolled = Math.ceil(this.objects.slider[0].scrollLeft / this.dimension.slideWidth);
    var slideDelta = itemsScrolled - this.state.itemIndexVisibleLeft;
    this.state.itemIndexVisibleLeft = Math.max(this.state.itemIndexVisibleLeft+slideDelta, 0);
    this.state.itemIndexVisibleRight = Math.min(this.state.itemIndexVisibleRight+slideDelta, this.state.numberOfItems-1);
    
    this.slideEnd();
};

HorizontalSlider.prototype.onScroll = function() {
    var scrollDiff = this.objects.slider[0].scrollLeft-this.scrollPos;
    this.scrollPos = this.objects.slider[0].scrollLeft;
    this.scrollDir = scrollDiff < 0 ? -1 : (scrollDiff > 0 ? 1 : this.scrollDir);

    var itemsScrolled = Math.ceil(this.objects.slider[0].scrollLeft / this.dimension.slideWidth);
    var slideDelta = itemsScrolled - this.state.itemIndexVisibleLeft;
    var minItemIndex = Math.max(this.state.itemIndexVisibleLeft+slideDelta, 0);
    var maxItemIndex = Math.min(this.state.itemIndexVisibleRight+slideDelta, this.state.numberOfItems-1);
    for(var i=minItemIndex,l=maxItemIndex;i<=l;i++) {
        this.callbacks.onSlideVisible(this.objects.slides[i], i);
    }
    if(this.scrolledTimeout) {
        clearTimeout(this.scrolledTimeout)
    }
    this.scrolledTimeout = setTimeout(jQuery.proxy(function() {
        this.onScrollEnd();
    }, this), 250);
};

HorizontalSlider.prototype.onResize = function(init) {
    if(!init && this.dimension.sliderWidth === this.objects.slider.width()) {
        return;
    }
    var slideWidth = this.objects.slides.width();
    this.dimension = {
        'sliderWidth' : this.objects.slider.width(),
        'slidesWidth' : this.objects.slider[0].scrollWidth,
        'slideWidth' : slideWidth,
        'slidesFitPartial' : Math.ceil(this.objects.slider.width() / slideWidth),
        'slidesFitFull' : Math.floor(this.objects.slider.width() / slideWidth)
    };
    this.state = {
        'slidesPerSlide' : Math.max(1, Math.ceil(this.dimension.slidesFitFull / 2)),
        'numberOfItems' : this.objects.slides.length,
        'itemIndexVisibleLeft' : 0,
        'itemIndexVisibleRight' : -1
    };
    for(var i=0,l=this.dimension.slidesFitPartial;i<l;i++) {
        this.state.itemIndexVisibleRight++;
        if(!this.objects.slides[this.state.itemIndexVisibleRight]) {
            this.state.itemIndexVisibleRight--;
            break;
        }
        this.callbacks.onSlideVisible(this.objects.slides[this.state.itemIndexVisibleRight], this.state.itemIndexVisibleRight);
    }
    this.objects.slider[0].scrollLeft = 0;
    this.slideEnd();
};

HorizontalSlider.prototype.slideEnd = function() {
    this.callbacks.onSlideEnd(this.objects.slides, this.state.itemIndexVisibleLeft, this.state.itemIndexVisibleRight);
    if(this.isLeftEnd()) {
        this.callbacks.onSlideEndLeft();
    }
    if(this.isRightEnd()) { // within 10px of border regard as scrolled to the end
        this.callbacks.onSlideEndRight();
    }
};

HorizontalSlider.prototype.isLeftEnd = function() {
    return this.objects.slider[0].scrollLeft === 0;
};

HorizontalSlider.prototype.isRightEnd = function() {
    var delta = Math.abs(this.dimension.slidesWidth-this.dimension.sliderWidth - this.objects.slider[0].scrollLeft);
    return delta < 10;
};