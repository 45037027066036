function juwelo_add_to_ajax_wishlist(url, success) {
    
    jQuery.getJSON(url, function(data) {
        if(data) {

            if(data.numberOfItems !== undefined) {
                jQuery('.trigger-link-wishlist .wishlist-amount').text(data.numberOfItems);
            }
            
            if(success) {
                success();
            }
        }
    });

};