window.friendlyCaptcha = {
    
    config: {
        sitekey: null,
        language: null,
        solutionFieldName: 'frc-captcha-solution'
    },
    state : {
        initialized: false,
        widget: null
    },
    
    init: function(sitekey, language, solutionFieldName) {
        
        this.config.sitekey = sitekey;
        this.config.language = language;
        if(solutionFieldName) {
            this.config.solutionFieldName = solutionFieldName;
        }
        
        // load scripts
        var head = document.getElementsByTagName('head')[0];
        
        // module script
        var scriptModule = document.createElement('script');
        scriptModule.async = 1;
        scriptModule.defer = 1;
        scriptModule.type = 'module';
        scriptModule.src = '/js/lib/friendly-challenge.0.9.10.widget.module.min.js';
        head.appendChild(scriptModule);
        
        // no module script
        var scriptNoModule = document.createElement('script');
        scriptNoModule.async = 1;
        scriptNoModule.defer = 1;
        scriptNoModule.nomodule = 1;
        scriptNoModule.src = '/js/lib/friendly-challenge.0.9.10.widget.polyfilled.min.js';
        head.appendChild(scriptNoModule);

        var self = this;
        var waitFriendlyCaptcha = window.setInterval(function() {
            if (!window.friendlyChallenge) {
                return;
            }
            window.clearInterval(waitFriendlyCaptcha);
            self.state.initialized = true;
        }, 500);
    },
    
    solve: function(widgetDomElement, doneCallback, errorCallback) {
        if(!this.state.initialized) {
            errorCallback('not-ready');
            return;
        }
        if(!this.state.widget) {
            var self = this;
            this.state.widget = new window.friendlyChallenge.WidgetInstance(widgetDomElement, {
                'puzzleEndpoint': 'https://eu-api.friendlycaptcha.eu/api/v1/puzzle',
                'startMode' : 'none',
                'sitekey' : this.config.sitekey,
                'language' : this.config.language,
                'solutionFieldName' : this.config.solutionFieldName,
                'doneCallback' : doneCallback,
                'errorCallback' : function() {
                    self.state.widget.reset();
                    errorCallback();
                }
            });
        } else {
            this.state.widget.reset();    
        }
        this.state.widget.start();
    }
};

/**
 * init page elements
 */
jQuery(document).ready(function() {

    // prepare global labels

    var body = jQuery('body');
    var labelShowMore = body.attr('data-labelShowMore');
    var labelShowLess = body.attr('data-labelShowLess');

    var run = function(callback) {
        try {
            callback();
        } catch (e) {
            _global_console.error(e);
        }
    };

    // cookie notice

    (function() {
        var notice = jQuery('#accept_cookies');
        if (notice.length === 0) {
            return;
        }

        var getBot = function() {
            if (navigator) {
                if (navigator.webdriver) {
                    return 'webdriver';
                }
                if (navigator.userAgent && /headless/i.test(navigator.userAgent)) {
                    return 'headless-ua';
                }
                if (navigator.appVersion && /headless/i.test(navigator.appVersion)) {
                    return 'headless-app';
                }
            }
            if (window.callPhantom || window._phantom || window.phantom) {
                return 'window-phantom';
            }
            if (document.__selenium_unwrapped || document.__webdriver_evaluate || document.__driver_evaluate) {
                return 'selenium';
            }
            return null;
        };

        var loadTracking = function(trackings) {
            
            // load ga and replay buffered calls

            if (typeof trackingLoadGoogleAnalytics === 'function' && trackings['google_analytics']) {
                trackingLoadGoogleAnalytics();
            }
            
            if (typeof trackingLoadGoogleAds === 'function' && trackings['google_adwords']) {
                trackingLoadGoogleAds();
            }

            // load content square

            if (typeof trackingLoadContentSquare === 'function' && trackings['content_square']) {
                trackingLoadContentSquare();
            }

            // load taboola

            if (typeof trackingLoadTaboola === 'function' && trackings['taboola']) {
                trackingLoadTaboola();
            }

            // load facebook and replay buffered calls

            if (typeof trackingLoadFb === 'function' && trackings['facebook']) {
                if (window.fbq && window.fbq.buffer) {
                    var q = window.fbq.q;
                    delete window.fbq;
                    trackingLoadFb();
                    for (var i = 0; i < q.length; i++) {
                        fbq.apply(null, q[i]);
                    }
                }
            }

            // load bing (will play buffered calls on its own)

            if (typeof trackingLoadBing === 'function' && trackings['bing']) {
                trackingLoadBing();
            }

            // load criteo (will play buffered calls on its own)

            if (typeof trackingLoadCriteo === 'function' && trackings['criteo']) {
                trackingLoadCriteo();
            }

            // load rtb house

            if (typeof trackingLoadRtbHouse === 'function' && trackings['rtb_house']) {
                trackingLoadRtbHouse();
            }

            // load awin

            if (trackings['awin']) {
                var script = jQuery('#awin-script');
                if (script.length > 0) {
                    if (script.attr('data-src')) {
                        var load = document.createElement('script');
                        load.defer = true;
                        load.src = script.attr('data-src');
                        jQuery('body').append(load);
                        script.remove();
                    }
                }
            }
        };

        var bot = getBot();
        var footer = jQuery('footer');
        var settings = notice.find('.settings');
        var buttonSettings = notice.find('.settings-toggle');
        var buttonAccept = notice.find('#accept_cookies_button');
        var buttonDecline = notice.find('.decline-cookies');
        var buttonStore = notice.find('.cookie-notice-store');
        var categoryCodes = {
            'analytics': [
                'google_analytics',
                'content_square',
                'hotjar'
            ],
            'marketing': [
                'facebook',
                'pinterest',
                'bing',
                'google_adwords',
                'awin',
                'rtb_house',
                'criteo',
                'daisycon',
                'google_remarketing',
                'taboola'
            ]
        };

        footer.css('padding-bottom', '40px');

        // open settings (only open, does not close anymore)

        buttonSettings.click(function(e) {
            e.preventDefault();

            buttonSettings.hide();
            settings.show();
            notice.addClass('configure');
            buttonAccept.text(buttonAccept.attr('data-alt'));

            jQuery.ajax({
                'url': '/misc_frontend/tracking/consentConfigure' + (bot ? '/b/' + bot : ''),
                'type': 'post'
            });
        });

        buttonAccept.click(function(e) {
            e.preventDefault();

            jQuery.ajax({
                'url': '/misc_frontend/tracking/consentAll' + (bot ? '/b/' + bot : ''),
                'type': 'post',
                'success': function(response) {
                    if (response.status === 'ok') {
                        // load all tracking codes we can on the fly
                        loadTracking({
                            'google_analytics': true,
                            'google_adwords': true,
                            'facebook': true,
                            'criteo': true,
                            'bing': true,
                            'content_square': true,
                            'hotjar': true,
                            'pinterest': true,
                            'taboola': true,
                            'awin': true,
                            'rtb_house' : true
                        });
                        gtag('event', 'cookie_consent');
                    }
                }
            });
            notice.hide();
            footer.css('padding-bottom', '0');
        });

        buttonStore.click(function(e) {
            e.preventDefault();

            var disable = [];
            var load = {};

            for (var category in categoryCodes) {
                var codes = categoryCodes[category];
                var checkbox = jQuery('#cookie-consent-' + category);

                // either

                if (checkbox.length > 0) {
                    if (checkbox[0].checked) {
                        for (var i = 0; i < codes.length; i++) {
                            load[codes[i]] = true;
                        }
                    } else {
                        for (var i = 0; i < codes.length; i++) {
                            disable.push(codes[i]);
                        }
                    }
                }
            }

            var data = {};
            for (var i = 0; i < disable.length; i++) {
                data['t[' + i + ']'] = disable[i];
            }

            jQuery.ajax({
                'url': '/misc_frontend/tracking/consentSettings' + (bot ? '/b/' + bot : ''),
                'type': 'post',
                'data': data,
                'success': function(response) {
                    if (response.status === 'ok') {
                        // load consented tracking codes we can on the fly
                        loadTracking(load);
                    }
                }
            });
            notice.hide();
            footer.css('padding-bottom', '0');
        });

        buttonDecline.click(function(e) {
            e.preventDefault();

            jQuery.ajax({
                'url': '/misc_frontend/tracking/consentRejectAll' + (bot ? '/b/' + bot : ''),
                'type': 'post',
                'success': function(response) {
                    if (response.status === 'ok') {
                        // load nothing
                    }
                }
            });
            notice.hide();
            footer.css('padding-bottom', '0');
        });

        jQuery.ajax({
            'url': '/misc_frontend/tracking/consentDialog' + (bot ? '/b/' + bot : ''),
            'type': 'post'
        });
    })();

    // ui state init

    (function(clientUiState) {
        function setState(showIfClass) {
            jQuery('.' + showIfClass).each(function(i, e) {
                var element = jQuery(e);
                var classes = element.attr('class');
                classes = classes.split(/\s+/);
                classesFiltered = '';
                for (var i = 0; i < classes.length; i++) {
                    var c = jQuery.trim(classes[i]);
                    if (!c.match(/^show\-if\-/)) {
                        classesFiltered += ' ' + c;
                    }
                }
                element.attr('class', classesFiltered);
            });
        }
        if (clientUiState.loggedIn) {
            setState('show-if-logged-in');
        } else {
            setState('show-if-logged-out');
        }
        if (clientUiState.freeShippingFromAmount && (
                clientUiState.freeShipping === 'not-free' ||
                clientUiState.freeShipping === 'amount-x'
            )) {
            // set state and render values
            setState('show-if-free-shipping-possible-from-amount-x');
            jQuery('.value-free-shipping-amount').html(clientUiState.freeShippingFromAmount.amount.html);
            jQuery('.value-free-shipping-missing-cart-amount').html(clientUiState.freeShippingFromAmount.missingCartAmount.html);
        } else {
            setState('show-if-free-shipping-' + clientUiState.freeShipping);
        }
    })(clientUiState);

    // header ticker

    (function() {
        var i = 5;
        window.setInterval(function() {
            var removeClass = 'show-' + i;
            i = (i % 5) + 1;
            var addClass = 'show-' + i;
            jQuery('.header-top').removeClass(removeClass).addClass(addClass);
        }, 5000);
    })();

    // hero slider

    (function() {
        var teaser = jQuery('#main-teaser');
        if (teaser.length === 0) {
            return;
        }

        var slidesInit = {};
        var prev = jQuery('.hero-teaser .slider-control.prev');
        var next = jQuery('.hero-teaser .slider-control.next');
        var dots = jQuery('.hero-teaser .slide-nav-dot');
        var slider = new HorizontalSlider({
            'slider': teaser,
            'slides': teaser.find('ul li')
        }, {
            'onSlideVisible': function(slideElement, slideIndex) {
                if (slidesInit[slideIndex]) {
                    return;
                }
                amg.removeDelay(slideElement.getElementsByClassName('x-adaptive-image')[0]);
                slidesInit[slideIndex] = true;
            },
            'onSlideEnd': function(slidesElements, slideIndexLeft, slideIndexRight) {
                prev.removeClass('disabled');
                next.removeClass('disabled');
                dots.removeClass('active');
                jQuery(dots[slideIndexLeft]).addClass('active');
            },
            'onSlideEndLeft': function() {
                // prev.addClass('disabled');
            },
            'onSlideEndRight': function() {
                // next.addClass('disabled');
            }
        }, {
            'scrollLock': true
        });

        prev.click(function() {
            if (slider.isLeftEnd()) {
                slider.slideTo(slider.state.numberOfItems);
            } else {
                slider.slideLeft();
            }
        });
        next.click(function() {
            if (slider.isRightEnd()) {
                slider.slideTo(0);
            } else {
                slider.slideRight();
            }
        });
        dots.each(function(i, e) {
            var dot = jQuery(e);
            (function(i, dot) {
                dot.click(function() {
                    slider.slideTo(i);
                });
            })(i, dot);
        });
    })();

    // header inline

    (function() {
        window.addEventListener('scroll', function() {

            var b = jQuery('body');
            var h = jQuery('#site-header');

            if (h.length === 0) {
                return;
            }

            var offset = h[0].offsetHeight;

            if (this.oldScroll > this.scrollY) {
                b.addClass('scroll-top');
                b.removeClass('scroll-bottom');

            } else {
                if (!b.hasClass('header-box-open')) {
                    b.removeClass('scroll-top');
                    b.addClass('scroll-bottom');
                }
            }
            if (this.scrollY === 0) {
                b.removeClass('scroll-bottom');
                b.removeClass('scroll-top');
            }

            this.oldScroll = this.scrollY;
        }, { 'passive': true });
    })();

    // nav panel

    (function() {

        if (jQuery('.main-navigation').length === 0) {
            return;
        }

        if (jQuery('html').hasClass('no-touchevents')) {

            // hover main nav
            jQuery('.tree').find('li').on('mouseenter', function() {
                jQuery(this).find('.x-adaptive-image-delay').each(function(i, e) { amg.removeDelay(e); });
                jQuery(this).find('.x-adaptive-image-delay').removeClass('x-adaptive-image-delay');
            });
        }

        // show more / less
        jQuery('.link-list').each(function() {
            if (jQuery(this).children().length > 12) {
                jQuery(this).append('<li class="more"><a class="more" href="javascript:;">' + labelShowMore + '</a></li>');
                jQuery(this).children("li:gt(9)").hide();
                jQuery(this).children('li.more').show();
            }
        });
        jQuery('.link-list .more a').click(function() {
            if (jQuery(this).hasClass('more')) {
                jQuery(this).removeClass('more');
                jQuery(this).addClass('less');
                jQuery(this).parent().siblings().show('slow');
            }
        });

        function resetShowMore() {
            jQuery('.link-list').each(function() {
                if (jQuery(this).children().length > 12) {
                    jQuery(this).children("li:gt(9)").hide();
                    jQuery(this).children('.more').show();
                    jQuery('a.less').text(labelShowMore).addClass('more');
                    jQuery('a.more').removeClass('less');
                }
            });
        }
    })();

    // mini login

    (function() {
        jQuery('#mini_login_button').click(function(e) {
            jQuery('.validation-advice').hide();
            e.preventDefault();
            var passed = true;
            var loginName = jQuery('#mini-login').val();
            var loginPassword = jQuery('#mini-password').val();
            if (loginName === '' || loginName.indexOf('@') === -1 || loginName.indexOf('.') === -1) {
                jQuery('#validation-advice-mini-login').show();
                passed = false;
            }
            if (loginPassword === '') {
                jQuery('#validation-advice-mini-password').show();
                passed = false;
            }
            if (passed === true) {
                jQuery('#mini-login-form').submit();
            }
        });
    })();

    // device type tracking cookie

    (function() {
        var inputType = Modernizr.touchevents ? 't' : 'c'; // touch or click
        var displayType = window.devicePixelRatio > 1 ? 'h' : 'l'; // highres or lowres 
        var screenSize = 't'; // tiny < 414
        if (window.screen.width >= 1800) {
            screenSize = 'h'; // huge >= 1800
        } else if (window.screen.width >= 1200) {
            screenSize = 'l'; // large >= 1200
        } else if (window.screen.width >= 768) {
            screenSize = 'm'; // medium >= 768
        } else if (window.screen.width >= 414) {
            screenSize = 's'; // small >= 414
        }

        var cookieValue = inputType + displayType + screenSize;
        var cookieName = 'elm_device';

        jQuery.cookie(cookieName, cookieValue, { 'expires': 300, 'path': '/', 'secure': 'true' });
    })();

    // trusted shops badge

    (function() {
        var badge = jQuery('#trust-custom-badge');
        if (badge.length === 0) {
            return;
        }
        var box = jQuery('#trustedshops-custom-box');
        badge.click(function() {
            box.show();
            badge.hide();
        });
        box.find('.close').click(function() {
            box.hide();
            badge.show();
        });
        jQuery('.trustedshops-custom-box-button').click(function() {
            box.hide();
            badge.show();
        });
        jQuery('.main-wrapper').click(function() {
            box.hide();
            badge.show();
        });
    })();

    // rings and chains-size info box

    (function() {
        jQuery('#show_size_info_link').click(function() {
            jQuery('#size_info_box').addClass('show');
        });
        jQuery('#size_info_box').find('.close').click(function() {
            jQuery('#size_info_box').removeClass('show');
        });
        jQuery('body').click(function(e) {
            var target = jQuery(e.target);
            if (target.closest('.size-info-box-content').length === 0 && !target.hasClass('show-size-info-link')) {
                jQuery('#size_info_box').removeClass('show')
            }
        });
    })();

    // add to cart confirmation dialog

    (function() {

        var close = function() {
            jQuery('#addedtocart').hide();
            jQuery('#addedtocart')[0].dispatchEvent(new CustomEvent('CartDialogClosed'));
            window._uxa.push(['trackPageview', window.location.pathname + window.location.hash.replace('#', '?__')]);
        };

        jQuery('#addedtocart').click(function(e) {
            if (!e.target.closest('.add-to-overlay-content')) {
                close();
            }
        });
        jQuery('.close-dialog').click(function() {
            close();
        });

    })();

    // buy now dialog

    (function() {

        var close = function() {
            jQuery('#buy-now').hide();
        };

        jQuery('#buy-now').click(function(e) {
            if (!e.target.closest('.add-to-overlay-content')) {
                close();
            }
        });
        jQuery('#buy-now .close-dialog').click(function() {
            close();
        });

        jQuery('#toggle-shipping-costs-table').click(function() {
            jQuery('.international-shipping-costs-table').toggleClass('hide-table');
        });

    })();

    // slider wrapper

    (function() {
        var wrapper = jQuery('#slider_wrapper');
        if (wrapper.length === 0) {
            return;
        }
        var delayed = jQuery('#slider_wrapper .x-adaptive-image-delay');

        jQuery('body').click(function() {
            if (wrapper.hasClass('show')) {
                wrapper.removeClass('show', 'slow');
            }
        });
        jQuery('.close-slide').click(function() {
            if (wrapper.hasClass('show')) {
                wrapper.removeClass('show', 'slow');
            }
        });
        jQuery('.slide-switch').click(function(event) {
            if (!wrapper.hasClass('show')) {
                delayed.each(function(i, e) { amg.removeDelay(e); });
                delayed.removeClass('x-adaptive-image-delay');
                wrapper.addClass('show', 'slow');
            } else {
                wrapper.addClass('hide', 'slow');
            }
            jQuery('.block-feed-reader a').attr('target', '_blank');
            event.stopPropagation(); // prevents close click to be triggered again on body
            return false;
        });
    })();

    // wishlist clicks in category 

    (function() {

        // init product grid add to wishlist links

        jQuery('.products-grid-item-wrapper a.add-to-wishlist[data-url]').click(function(e) {
            e.preventDefault();

            var a = jQuery(this);
            var li = a.parents('.products-grid-item:eq(0)');
            var sku = li.attr('id').replace('products-grid-item-', '');
            var url = atob(a.attr('data-url')).replace(':FORM_KEY_PLACEHOLDER:', window.mageFormKey);

            if (li.hasClass('is-on-wishlist')) {
                return;
            }

            li.find('.products-grid-item-wrapper').addClass('adding-to-wishlist');

            juwelo_add_to_ajax_wishlist(url, function() {

                li.find('.adding-to-wishlist').removeClass('adding-to-wishlist');
                li.addClass('is-on-wishlist');
                window._uxa.push(['trackPageEvent', 'Wishlist Product Added']);
                window.algolia.add_to_wishlist(sku, null);

            });
        });
    })();

    // featured products / recently-seen sliders

    (function() {
        var elements = jQuery('section.featured-products,section.recent-products,section.brands-slider,.birthstones-navigator-slider,.birthstone-products, .feature-category-slider-container');
        for (var i = 0, l = elements.length; i < l; i++) {
            var element = jQuery(elements[i]);
            (function(element) {

                var prev = element.find('.slider-control.prev');
                var next = element.find('.slider-control.next');
                var slider = new HorizontalSlider({
                    'slider': element.find('.horizontal-slider'),
                    'slides': element.find('.horizontal-slider ul li')
                }, {
                    'onSlideVisible': function(slideElement, slideIndex) {
                        amg.removeDelay(slideElement.getElementsByClassName('x-adaptive-image')[0]);
                    },
                    'onSlideEnd': function(slidesElements, slideIndexLeft, slideIndexRight) {
                        prev.removeClass('disabled');
                        next.removeClass('disabled');
                    },
                    'onSlideEndLeft': function() {
                        prev.addClass('disabled');
                    },
                    'onSlideEndRight': function() {
                        next.addClass('disabled');
                    }
                });
                prev.click(function() { slider.slideLeft(); });
                next.click(function() { slider.slideRight(); });

            })(element);
        }
    })();

    // toggle stuff

    (function() {
        jQuery('.toggle-text').each(function(i) {
            if (jQuery(this).children().length > 1) {
                jQuery(this).append('<div class="more"><a href="javascript:;"></a></div>');
                jQuery(this).children(":first").addClass('show');
                jQuery(this).children(":first").next().addClass('show');
                jQuery(this).children('.more').addClass('show');
                jQuery(this).children('.more').find('a').text(labelShowMore);
                jQuery(this).children().not(".show").hide();
            }
        });

        jQuery('.toggle-text .more a').click(function() {
            if (jQuery(this).parent().is('.show')) {
                jQuery(this).parent().siblings().slideDown('slow');
                jQuery(this).text(labelShowLess);
            } else {
                jQuery(this).parent().siblings().not('.show').slideUp('slow');
                jQuery(this).text(labelShowMore);
            }
            jQuery(this).parent().toggleClass('show');
        });

        /* videothek */
        jQuery('.sub-list').each(function() {
            if (jQuery(this).children().length > 5) {
                jQuery(this).append('<li class="more"><a href="javascript:;">' + labelShowMore + '</a></li>');
                jQuery(this).children(":first").addClass('show');
                jQuery(this).children(":nth-child(1)").next().addClass('show');
                jQuery(this).children(":nth-child(2)").next().addClass('show');
                jQuery(this).children('.more').addClass('show');
                jQuery(this).children().not(".show").hide();
            }
        });

        jQuery('.sub-list .more a').click(function() {
            if (jQuery(this).parent().is('.show')) {
                jQuery(this).parent().siblings().slideDown('slow');
                jQuery(this).text(labelShowLess);
            } else {
                jQuery(this).parent().siblings().not('.show').slideUp('slow');
                jQuery(this).text(labelShowMore);
            }
            jQuery(this).parent().toggleClass('show');
        });
    })();

    // newsletter widget

    (function() {

        var section = jQuery('#newsletter_subscribe');
        if (section.length === 0) {
            // no widget on page? we're finished
            return;
        }

        // prepare vars

        var mode = section.attr('data-mode'); // popup or default

        // prepare popup handling

        var popup = {
            'cookieName': 'elm_nlp',
            'ui': {
                'close': section.find('#close_nl_popup'),
            },
            'setCookie': function(expires) {
                jQuery.cookie(this.cookieName, 1, { 'expires': expires, 'path': '/' });
            },
            'close': function() {
                section.removeClass('popup-mode');
                this.ui.close.hide();
                mode = 'default';
                amg.resize();
            }
        };
        popup.ui.close.click(function() {
            popup.close();
            popup.setCookie(30);
            window._uxa.push(['trackPageview', window.location.pathname + window.location.hash.replace('#', '?__')]);
        });

        if (mode === 'popup' && jQuery.cookie(popup.cookieName) !== '1') {
            window.setTimeout(function() {
                section.addClass('popup-mode');
                popup.ui.close.removeAttr('style');
                section.find('form input[name="source"]').val('popup'); // change source
                amg.scroll();
                window._uxa.push(['trackPageview', window.location.pathname + window.location.hash.replace('#', '?__') + '?cs-popin-newsletter']);
            }, 10000);
        }
        
        // form validation before submit
        
        var emailInput = jQuery('#newsletter_widget_email_address');
        var emailAdvice = jQuery('.validation-advice-email');
        var friendlyCaptchaContainer = jQuery('.input-box-frc-captcha');
        var friendlyCaptchaWidget = friendlyCaptchaContainer.find('.friendly-captcha-widget')[0];
        var hideDuringChallenge = section.find('.input-box-email,button');
        
        // init captcha

        window.friendlyCaptcha.init(
            friendlyCaptchaContainer.data('sitekey'),
            friendlyCaptchaContainer.data('language')
        );
        
        var form = jQuery('#newsletter_widget_form');
        form.submit(function(e) {
            e.preventDefault();
            
            if(jQuery.trim(emailInput.val()) == '') {
                emailAdvice.show();
                return false;
            } else {
                emailAdvice.hide();
            }
            
            if (mode === 'popup') {
                popup.setCookie(30);
            }

            hideDuringChallenge.hide();
            
            // solve captcha and submit form when finished
            
            window.friendlyCaptcha.solve(
                friendlyCaptchaWidget, 
                function(solution) {
                    if(solution) {
                        form[0].submit();
                    } else {
                        hideDuringChallenge.show();
                    }
                },
                function() {
                    hideDuringChallenge.show();
                }
            );
            return false;
        });

    })();

    // footer toggle-links

    (function() {
        jQuery('.toggle-links').each(function(i, e) {

            var element = jQuery(e);
            var all = element.find('li');
            var rest = element.find("li:gt(6)");

            if (all.length > 7) {

                rest.hide();
                element.append('<li class="more show"><a href="#"></a></li>');
                var more = element.find('.more');
                var link = more.find('a');

                link.text(labelShowMore);
                link.click(function(e) {
                    e.preventDefault();
                    if (more.hasClass('show')) {
                        rest.show('slow');
                        more.removeClass('show');
                        link.text(labelShowLess);
                    } else {
                        rest.hide('slow');
                        more.addClass('show');
                        link.text(labelShowMore);
                    }
                });
            }
        });
    })();

    // retour link (nl?)

    (function() {
        if (jQuery('body').hasClass('nl')) {
            var accountNavPos = jQuery('.block-sidenav.block-account > .block-content > ul > li:nth-child(7)');
            var link = jQuery('<li/>', { html: jQuery('<a/>', { class: 'account_retour_link', href: '/retour/', title: 'Retouren', text: 'Retouren' }) });
            jQuery(link).insertAfter(accountNavPos);
        }
    })();

    // some toggle

    (function() {
        jQuery('.block-layered-nav > .block-title').click(function() {
            jQuery('.block-layered-nav').toggleClass('opened');
        });

        /* more link */

        jQuery('.more').click(function(e) {
            e.preventDefault();
            var link = jQuery(this);
            var hidden = link.parent().find('.hidden-text');
            if (hidden.length === 0) {
                return;
            }
            hidden.slideDown();
            link.hide();
            link.parent().find('.less').show();
        });

        jQuery('.less').click(function(e) {
            e.preventDefault();
            var link = jQuery(this);
            var hidden = link.parent().find('.hidden-text');
            if (hidden.length === 0) {
                return;
            }
            hidden.slideUp();
            link.hide();
            link.parent().find('.more').show();
        });

        jQuery('.toggle-password').click(function() {
            var pwInputID = jQuery(this).attr('data-toggle');
            var pwInput = document.getElementById(pwInputID);
            if (pwInput.type === "password") {
                pwInput.type = "text";
                jQuery(this).addClass('hide');
            } else {
                pwInput.type = "password";
                jQuery(this).removeClass('hide');
            }
        });
    })();

    // index nav builder for category descriptions

    // Home Descrition Index Nav
    var entries = jQuery('.category-description h2');
    var numberOfEntries = entries.length;
    if (numberOfEntries > 1) {
        var index = jQuery('<ol>', {
            id: 'description-index'
        });
        var i = 1;

        entries.each(function() {
            if (jQuery(this).html() !== '') {
                var text = i + '. ' + jQuery(this).html();
                jQuery(this).html(text);
                var link = jQuery('<li>', {
                    html: jQuery('<a>', {
                        class: 'page-link',
                        href: '#s' + i,
                        title: jQuery(this).text(),
                        text: jQuery(this).text()
                    })
                });
                jQuery(this).attr('id', 's' + i);
                index.append(link);
                i++;
            }
        });

        jQuery('.category-description-first-clear').after(index);
        index.before(jQuery('<h4>', {
            class: 'index-title',
            text: jQuery('body').attr('data-labelTableOfContents')
        }));
    }

    /* scroll to hashtag */

    jQuery(".more-details").click(function(event) {
        event.preventDefault();
        //calculate destination place
        var dest = 0;
        if (jQuery(this.hash).offset().top > jQuery(document).height() - jQuery(window).height()) {
            dest = jQuery(document).height() - jQuery(window).height();
        } else {
            dest = jQuery(this.hash).offset().top;
        }
        //go to destination
        jQuery('html,body').animate({ scrollTop: dest }, 200, 'swing');
    });

    /* scoll to top */

    jQuery('.scroll-to-top').click(function(event) {
        event.preventDefault();
        var dest = 0;
        jQuery('html,body').animate({ scrollTop: dest }, 1000, 'swing');
    });

    jQuery('.to-top').click(function(event) {
        event.preventDefault();
        var dest = 0;
        jQuery('html,body').animate({ scrollTop: dest }, 1000, 'swing');
    });

    run(function() {

        var jqWindow = jQuery(window);
        var scrollToTop = jQuery('.scroll-to-top');

        window.addEventListener('scroll', function() {
            if (jqWindow.scrollTop() < 2000) {
                scrollToTop.fadeOut();
            } else {
                scrollToTop.fadeIn();
            }
        }, { passive: true });

    });

    // sticky checkout bar in cart

    run(function() {

        var bar = jQuery('#sticky_checkout_bar');
        if (bar.length === 0) {
            return;
        }

        var jqWindow = jQuery(window);
        var buttons = jQuery('#checkout-types-bottom');
        var windowHeight = jqWindow.height();
        var buttonsHeight = buttons.height();

        var showHide = function() {
            if (jqWindow.scrollTop() > buttons.offset().top + buttonsHeight - windowHeight) {
                if (!bar.hasClass('hide-bar')) {
                    bar.addClass('hide-bar');
                }
            } else {
                bar.removeClass('hide-bar');
            }
        };
        showHide();

        window.addEventListener('scroll', showHide, { passive: true });
        window.addEventListener('resize', function() {
            windowHeight = jqWindow.height();
            buttonsHeight = buttons.height();
        });

    });

    /* creation */

    jQuery('#creation_info').prepend('<span class="close"></span>');
    jQuery('#creation_info .close').click(function() {
        jQuery('#creation_info').fadeOut();
    });

    jQuery('#creation_link .open').click(function(e) {
        jQuery('#creation_info').fadeToggle();
    });

    // hide with outside click
    jQuery(document).bind('click', function(event) {
        if (!(jQuery(event.target).parents().andSelf().is('#creation_link .open')) && !(jQuery(event.target).parents().andSelf().is('#creation_info'))) {
            jQuery('#creation_info').fadeOut();
        }
    });

    jQuery('.show-big').click(function() {
        var fader = jQuery('<div/>', { class: 'cms-image-zoom-box-fader' });
        var zoomBox = jQuery('<div/>', { class: 'cms-image-zoom-box', style: 'display: none;' });
        var inner = jQuery('<div/>', { class: 'inner' });
        var close = jQuery('<div/>', { class: 'close close-big' });
        var img = jQuery(this).clone();
        img.removeClass('show-big');
        jQuery('body').append(fader);
        jQuery('body').append(zoomBox);
        zoomBox.append(inner);
        jQuery('.cms-image-zoom-box .inner').append(close);
        jQuery('.cms-image-zoom-box .inner').append(img);
        zoomBox.show('fade');
        jQuery('.close-big').on('click', function() {
            fader.hide('fade', function() { fader.remove() });
            zoomBox.hide('fade', function() { zoomBox.remove() });
        });
        fader.on('click', function() {
            fader.hide('fade', function() { fader.remove() });
            zoomBox.hide('fade', function() { zoomBox.remove() });
        });
    });


    jQuery('.open-login-layer').on('click', function() {
        if (jQuery(this).attr('title') || jQuery(this).attr('title') != '') {
            openLoginLayer(jQuery(this).attr('title'));
        } else {
            openLoginLayer('');
        }
    });

    // Trigger-Links incl. Menu-Trigger
    jQuery('.trigger-link').not('.trigger-link-cart,.trigger-link-wishlist').on('click', function(e) {
        e.preventDefault();

        if (jQuery(window).width() < 640) {
            var mobileAlt = jQuery(this).attr('data-toggle-mobile-alt');
            if (mobileAlt) {
                document.location.href = mobileAlt;
                return;
            }
        }


        if (jQuery(this).hasClass('link-active')) {

            // close

            jQuery(this).removeClass('link-active');
            jQuery('.box-open').removeClass('box-open');
            jQuery('body').removeClass('header-box-open');
            jQuery('.etrusted-badge-container').addClass('etrusted-badge-container--visible');
            jQuery('.etrusted-badge-container').removeClass('etrusted-badge-container--hidden');

        } else {

            // open

            window._uxa.push(['trackPageview', window.location.pathname + window.location.hash.replace('#', '?__') + '?' + 'cs-modal-' + jQuery(this).attr('data-toggle')]);

            var toggleTarget = jQuery('.' + jQuery(this).attr('data-toggle'));
            jQuery('.link-active').removeClass('link-active');
            jQuery(this).addClass('link-active');
            jQuery('.box-open').removeClass('box-open');
            jQuery('body').addClass('header-box-open');
            toggleTarget.addClass('box-open');
            toggleTarget.find('.x-adaptive-image-delay').each(function(i, e) {
                amg.removeDelay(e);
            });
            toggleTarget.find('.x-adaptive-image-delay').removeClass('x-adaptive-image-delay');
            var dest = 0;
            jQuery('html,body').animate({ scrollTop: dest }, 100, 'swing');

            if (jQuery('html').hasClass('touchevents')) {
                var maxHeight = jQuery(window).height() - jQuery('.header-main').height();
                jQuery('.header-content-box').css('max-height', maxHeight);
                jQuery('.etrusted-badge-container').removeClass('etrusted-badge-container--visible');
                jQuery('.etrusted-badge-container').addClass('etrusted-badge-container--hidden');
            }
        }
    });

    jQuery('.close-menu').click(function () {
        jQuery('.link-active').removeClass('link-active');
        jQuery('.box-open').removeClass('box-open');
        jQuery('body').removeClass('header-box-open');
    });

    // click outside
    jQuery('body').click(function(e) {
        var target = jQuery(e.target);
        if (target.closest('.link-active').length === 0 &&
            target.closest('.box-open').length === 0 &&
            target.closest('.open-login-layer').length === 0 &&
            !jQuery('html').hasClass('locked')
        ) {
            jQuery('.link-active').removeClass('link-active');
            jQuery('.box-open').removeClass('box-open');
            jQuery('body').removeClass('header-box-open');
            jQuery('.etrusted-badge-container').addClass('.etrusted-badge-container--visible');
            jQuery('.etrusted-badge-container').removeClass('.etrusted-badge-container--hidden');
        }
    });
    // click close
    jQuery('.header-content-box-close').on('click', function() {
        jQuery('.trigger-link').removeClass('link-active');
        jQuery('.header-content-box').removeClass('box-open');
        jQuery('body').removeClass('header-box-open');
        jQuery('.etrusted-badge-container').addClass('.etrusted-badge-container--visible');
        jQuery('.etrusted-badge-container').removeClass('.etrusted-badge-container--hidden');
    });

    // handle dynmic forms

    function defaultborder(e) {
        e.css('border-color', '');
    }

    function pinkborder(e) {
        e.css('border-color', '#b4146e');
    }

    function inputState(e, isFocus) {
        var box = e.parent('.input-box');
        if (jQuery.trim(e.val()) !== '' || isFocus) {
            box.addClass('input-box-filled');
        } else {
            box.removeClass('input-box-filled');
        }
    }

    var inputs = jQuery('.dynamic-label input');
    var selects = jQuery('.dynamic-label select');

    inputs.on('focus', function() { pinkborder(jQuery(this)); });
    inputs.on('blur', function() { defaultborder(jQuery(this)); });
    selects.on('focus', function() { pinkborder(jQuery(this).parent()); });
    selects.on('blur', function() { defaultborder(jQuery(this).parent()); });

    inputs.change(function() { inputState(jQuery(this), false); });
    inputs.blur(function() { inputState(jQuery(this), false); });
    inputs.focus(function() { inputState(jQuery(this), true); });

    inputs.each(function(i, e) {
        inputState(jQuery(e), false);
        jQuery(e).on('animationend webkitAnimationEnd oAnimationEnd MSAnimationEnd', function() {
            jQuery(this).parent('.input-box').addClass('input-box-filled');
        });
    });

    window.setTimeout(function() {}, 2000);

    // ensure correct input state on switch step
    jQuery('#checkoutSteps .step-title').click(function() {
        inputs.each(function(i, e) { inputState(jQuery(e), false); });
    });

    jQuery('.link-additional').click(function() {
        jQuery(this).hide();
        if (jQuery(this).hasClass('show-additional')) {
            jQuery('.additional-address').show();
            jQuery('.hide-additional').show();
        }
        if (jQuery(this).hasClass('hide-additional')) {
            jQuery('.additional-address').hide();
            jQuery('.show-additional').show();
        }
    });

    // check for entered street number
    jQuery('.validate-street-no').blur(function() {
        value = jQuery(this).attr('value');
        if (value != '') {
            checkNumber(value);
        }
    });
    jQuery('.validate-street-no').focus(function() {
        jQuery('.input-advice-streetno').hide();
    });
    jQuery('.close-advice').click(function() {
        jQuery('.input-advice-streetno').hide();
    });

    function checkNumber(value) {
        if (value.match(/[0-9]/) != null) {
            return true;
        } else {
            jQuery('.input-advice-streetno').show();
        }
    }

    /* plugin for countdown */
    (function(jQuery) {

        jQuery.fn.countdown = function(options) {

            if (options.seconds) {
                options.endDate = new Date();
                options.endDate.setSeconds(options.endDate.getSeconds() + options.seconds);
            }

            var el = this;
            var settings = jQuery.extend({
                endDate: new Date()
            }, options);
            setupHtml();
            updateClock(settings.endDate);

            function updateClock(endDate) {
                var now = jQuery.now();
                var end = endDate;
                var remaining = (end - now) / 1000;
                var d = Math.floor(remaining / 86400);
                remaining -= d * 86400;
                if (d < 10) { d = '0' + d } else { d = d + '' }
                d = wrapDigits(d);
                var h = Math.floor(remaining / 3600) % 24;
                remaining -= h * 3600;
                if (h < 10) { h = '0' + h } else { h = h + '' }
                h = wrapDigits(h);
                var m = Math.floor(remaining / 60) % 60;
                remaining -= m * 60;
                if (m < 10) { m = '0' + m } else { m = m + '' }
                m = wrapDigits(m);
                var s = Math.floor(remaining % 60);
                if (s < 10) { s = '0' + s } else { s = s + '' }
                s = wrapDigits(s);
                var time = [d, h, m, s];
                setTimeout(function() {
                    updateClock(endDate);
                }, 1000);
                return updateOutput(time);
            }

            function updateOutput(time) {
                jQuery('.days').html(time[0]);
                jQuery('.hours').html(time[1]);
                jQuery('.minutes').html(time[2]);
                jQuery('.seconds').html(time[3]);
            }

            function setupHtml() {
                var wrap = jQuery('<div/>').addClass('countdown-wrapper');
                el.append(wrap);
                var dBox = jQuery('<span/>').addClass('cbox days');
                var hBox = jQuery('<span/>').addClass('cbox hours');
                var mBox = jQuery('<span/>').addClass('cbox minutes');
                var sBox = jQuery('<span/>').addClass('cbox seconds');
                wrap.append(dBox, hBox, mBox, sBox);
            }

            function wrapDigits(digits) {
                digits = digits.split("");
                var wrapped = digits.map(function(digit) {
                    return '<span>' + digit + '</span>';
                });
                wrapped = wrapped.join("");
                return wrapped;
            }

        };

    }(jQuery));


    /* paste to clipboard */
    jQuery('.copy-voucher-code').on('click', function(e) {
        e.preventDefault();
        jQuery('.copy-voucher-code').removeClass('copied');
        var clicked = jQuery(this);
        if(clicked.attr('href')){
            var href = clicked.attr('href');
        }
        //
        if(clicked.is('.voucher-code')) {
            var text = clicked.text();
        } else {
            if(jQuery('.voucher-code').length > 1){
                var text = clicked.find('.voucher-code').text();
            } else {
                var text = jQuery('.voucher-code').text();
            }
        }

        if (!navigator.clipboard) {
            var textArea = document.createElement("textarea");
            textArea.value = text;

            // Avoid scrolling to bottom
            textArea.style.top = "0";
            textArea.style.left = "0";
            textArea.style.position = "fixed";

            document.body.appendChild(textArea);
            textArea.focus();
            textArea.select();

            try {
                var successful = document.execCommand('copy');
                var msg = successful ? 'successful' : 'unsuccessful';
                jQuery('.voucher-code').addClass('copied');

                if (href) {
                    window.setTimeout(function() {
                        window.location = href;
                    }, 750);
                }
            } catch (err) {
                _global_console.log('Fallback: Oops, unable to copy', err);
            }
            document.body.removeChild(textArea);
            return;
        } else {
            navigator.clipboard.writeText(text).then(function() {
                clicked.addClass('copied');
                jQuery('.copied-msg b').text(text);

                if (href) {
                    window.setTimeout(function() {
                        window.location = href;
                    }, 750);
                }
            }, function(err) {
                _global_console.log('Async: Could not copy text: ', err);
            });
        }


    });

    jQuery('.copied-msg .close').click(function (){
        jQuery('.copy-voucher-code').removeClass('copied');
    });


    /* content accordeon */
    jQuery('.content-accordeon > .item > .title').click(function() {
        var accordeonItem = jQuery(this).parent('.content-accordeon > .item');
        accordeonItem.toggleClass('open');
    });

    /* used on about-page */
    var hash = window.location.hash;
    if (jQuery(hash).hasClass('item')) {
        if (!jQuery(hash).hasClass('open')) {
            jQuery(hash).addClass('open');
        }
    }
    jQuery('.accordeon-link').click(function() {
        target = jQuery(this).attr('href');
        if (!jQuery(target).hasClass('open')) {
            jQuery(target).addClass('open');
        }
    });


    /* longtext toggle birthstones */
    jQuery('.birthstone-info-link.show-more-less').click(function() {
        var longtextId = jQuery(this).attr('data-toggle');
        jQuery(this).toggleClass('show-longtext');
        jQuery(longtextId).toggleClass('show-longtext');
    });
    jQuery('.show-longtext').click(function() {
        var longtextId = jQuery(this).attr('data-toggle');
        var dest = 0;
        if (jQuery(this.hash).offset().top > jQuery(document).height() - jQuery(window).height()) {
            dest = jQuery(document).height() - jQuery(window).height();
        } else {
            dest = jQuery(this.hash).offset().top;
        }
        //go to destination
        jQuery('html,body').animate({ scrollTop: dest }, 200, 'swing');
        jQuery(longtextId).removeClass('show-longtext');
        jQuery(longtextId).addClass('show-longtext');
        jQuery(longtextId).removeClass('show-longtext');
        jQuery(longtextId).addClass('show-longtext');
    });

    /* live-auction / channels banner */

    jQuery('a.channel-live-auction').click(function (e){
        var livePageClass = ['cms-live-auktion', 'cms-tele-achat', 'cms-veiling', 'cms-subasta', 'cms-live-auction', 'cms-acquisto-in-diretta'];
        if(checkBodyClass(livePageClass)){
            e.preventDefault();
        }
    });

    jQuery('a.channel-2').click(function (e){
        var channel2PageClass = ['cms-kanal-2',  'cms-canale-2',  'cms-chaine-2',  'cms-canal-2',  'cms-kanaal-2',  'cms-channel-2'];
        if(checkBodyClass(channel2PageClass)){
            e.preventDefault();
        }
    });

    jQuery('a.channel-3').click(function (e){
        var channel3PageClass = ['cms-kanal-3',  'cms-canale-3',  'cms-chaine-3',  'cms-canal-3',  'cms-kanaal-3',  'cms-channel-3'];
        if(checkBodyClass(channel3PageClass)){
            e.preventDefault();
        }
    });

    jQuery('a.channel-4').click(function (e){
        var channel4PageClass = ['cms-kanal-4',  'cms-canale-4',  'cms-chaine-4',  'cms-canal-4',  'cms-kanaal-4',  'cms-channel-4'];
        if(checkBodyClass(channel4PageClass)){
            e.preventDefault();
        }
    });

    jQuery('a[href^="mailto:"]').each(function(e) {
        var href = jQuery(this).attr('href');

        if(href.includes('influencer@juwelo')){
            href = href + "?subject=Influencer Request&body=%0D%0A%0D%0A----------------------------------%0D%0AInstagram: "
        } else {
            if( clientUiState.mailtoContent ) {
                href = href + "?body="+clientUiState.mailtoContent;
            }
        }

        jQuery(this).attr('href', href);
    });

    // delivery country select

    var countrySelectToggle = jQuery('.text-availability').find('.country');
    var countrySelectList = jQuery('.select-delivery-country');

    jQuery('body').click(function() {
        countrySelectToggle.filter('.country-select-open').removeClass('country-select-open');
        countrySelectList.hide();
    });

    countrySelectToggle.click(function(e) {
        e.stopPropagation();
        countrySelectToggle.toggleClass('country-select-open');
        countrySelectList.toggle();
    });

    countrySelectList.find('li').click(function() {
        console.log('clicked on '+this.dataset.country);
        document.body.dataset.deliveryCountry = this.dataset.country.toLowerCase();
        fetch('/misc_frontend/settings/country/code/'+this.dataset.country, { method: 'POST', credentials: 'include' });
        
        // open select will be closed by propagated click on body
    });

});

function checkBodyClass(classesArray) {
    for (var i = 0; i < classesArray.length; i++) {
        if(jQuery('body').hasClass(classesArray[i])){
            return true;
        }
    }
    return false;
}

function openLoginLayer(text) {
    var block = jQuery('.block-login .block-title');
    if (text !== '') {
        var info = jQuery('<p/>', { 'class': 'info', 'text': text });
        if (block.hasClass('p-info')) {
            block.children('.info').text(text);
        } else {
            block.prepend(info);
            block.addClass('p-info')
        }
    } else {
        block.removeClass('p-info')
    }
    jQuery('.trigger-link-account').addClass('link-active');
    jQuery('.header-content-box-account').addClass('box-open');
}

function getDeviceTypeSuffix() {
    var deviceTypeSuffix = Modernizr.touchevents ? 'touch' : 'click'; // touch or click
    if (window.screen.width >= 1800) {
        deviceTypeSuffix = deviceTypeSuffix + '/huge'; // huge >= 1800
    } else if (window.screen.width >= 1200) {
        deviceTypeSuffix = deviceTypeSuffix + '/large'; // large >= 1200
    } else if (window.screen.width >= 768) {
        deviceTypeSuffix = deviceTypeSuffix + '/medium'; // medium >= 768
    } else if (window.screen.width >= 414) {
        deviceTypeSuffix = deviceTypeSuffix + '/small'; // small >= 414
    } else {
        deviceTypeSuffix = deviceTypeSuffix + '/tiny'; // tiny < 414
    }
    return deviceTypeSuffix;
}